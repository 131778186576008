<template>
  <a-drawer
    :title="data.name + '——抵扣券'"
    width="800"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 功能条 -->
    <div class="tool-view">
      <div>
        弹出付费面板赠送抵扣券：
        <a-switch
          checked-children="开"
          un-checked-children="关"
          :disabled="isLoading"
          v-model:checked="swicthStatus"
          @change="changeSwitch"
        />
      </div>
      <!-- 弹簧 -->
      <div style="flex: 1;"></div>
      <!-- 操作 -->
      <a-button type="primary" @click="touchEdit({})">
        <plus-outlined />
        新建
      </a-button>
    </div>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="false"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record, index }">
        <!-- 注册时长 -->
        <template v-if="column.key === 'register_duration'">
          {{ record[column.key] }}小时前
        </template>
        <!-- 充值次数 -->
        <template v-if="column.key === 'pay_num'">
          小于{{ record[column.key] }}次
        </template>
        <!-- 最大充值金额 -->
        <template v-if="column.key === 'pay_max_money'">
          小于{{ record[column.key] }}元
        </template>
        <!-- 抵扣券 -->
        <template v-if="column.key === 'deduction'">
          {{ record.deduction.name }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'action'">
          <!-- 上移 -->
          <a-button
            type="link"
            :disabled="index == 0"
            @click="move(record, '1')"
          >
            上移
          </a-button>
          <!-- 下移 -->
          <a-button
            type="link"
            :disabled="index === dataSource.length - 1"
            @click="move(record, '2')"
          >
            下移
          </a-button>
          <!-- 停用 -->
          <a-button
            type="link"
            @click="stop(record)"
          >
            {{ record.status === 1 ? '停用' : '开启'}}
          </a-button>
          <!-- 编辑 -->
          <a-button
            type="link"
            @click="touchEdit(record)"
          >
            编辑
          </a-button>
        </template>
      </template>
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 新增\编辑券 -->
    <CouponNewDrawer ref="RefCouponNewDrawer" @success="getList" />
  </a-drawer>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import CouponNewDrawer from './CouponNewDrawer'
import { popularizeDeductionList, popularizeDeductionSwitch, popularizeDeductionStatus, popularizeDeductionSort } from '@/api/operate'

// 券
let RefCouponNewDrawer = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 是否开启
let swicthStatus = ref(false)
// 当前数据
let data = ref({})
// 数据源
let dataSource = ref([])
// 列数据源
let columns = ref([
  {
    title: '判断顺序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '注册时长',
    dataIndex: 'register_duration',
    key: 'register_duration'
  },
  {
    title: '充值次数',
    dataIndex: 'pay_num',
    key: 'pay_num'
  },
  {
    title: '最大充值金额',
    dataIndex: 'pay_max_money',
    key: 'pay_max_money'
  },
  {
    title: '抵扣券',
    dataIndex: 'deduction',
    key: 'deduction'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action'
  }
])

// 显示
function showDrawer (record) {
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 获取数据详情列表
function getList () {
  isLoading.value = true
  const params = {
    adv_id: data.value.id
  }
  popularizeDeductionList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 开关（1开启 2关闭）
      swicthStatus.value = data.deduction_status === 1 ? true : false
      // 列表
      dataSource.value = data.list
    } else {
      message.error(res.message || msg)
    }
  })
}

// 切换总开关
function changeSwitch () {
  isLoading.value = true
  const params = {
    id: data.value.id,
    // 开关（1开启 2关闭）
    deduction_status: swicthStatus.value ? 1 : 2
  }
  popularizeDeductionSwitch(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      message.success('操作成功')
    } else {
      message.error(res.message || msg)
    }
  })
}

// 新增、编辑
function touchEdit (record) {
  RefCouponNewDrawer.value.showDrawer({
    ...record,
    adv_id: data.value.id
  })
}

// 上移、下移
function move (record, type) {
  isLoading.value = true
  popularizeDeductionSort({
    id: record.id,
    // 1上移 2下移
    move_type: type
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 开启停用
function stop (record) {
  isLoading.value = true
  popularizeDeductionStatus({
    id: record.id,
    // 1启用 2停用
    status: record.status === 1 ? 2 : 1
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
}
</style>