import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ===================================《 公共 》

// 客户端列表
export function platformList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/reseller_platform_list',
    method: 'get',
    params: parameter
  })
}

// 投手列表接口
export function pitcherList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/pitcher_list',
    method: 'get',
    params: parameter
  })
}

// 金额汇总列表
export function moneyList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/money_list',
    method: 'get',
    params: parameter
  })
}

// 短剧列表（搜索）
export function dramaAll(parameter) {
  return axios({
    url: BASE_URL + '/reseller/drama_all',
    method: 'get',
    params: parameter
  })
}

// ===================================《 首页 》

// 首页 - 今日充值
export function dataPay(parameter) {
  return axios({
    url: BASE_URL + '/reseller/home_pay',
    method: 'get',
    params: parameter
  })
}

// 首页 - 今日充值用户
export function dataPayUser(parameter) {
  return axios({
    url: BASE_URL + '/reseller/home_pay_user',
    method: 'get',
    params: parameter
  })
}

// 首页 - 今日访问用户
export function dataNewUser(parameter) {
  return axios({
    url: BASE_URL + '/reseller/home_new_user',
    method: 'get',
    params: parameter
  })
}

// 首页 - 今日充值率 + 待入库数
export function dataPayRate(parameter) {
  return axios({
    url: BASE_URL + '/reseller/home_pay_rate',
    method: 'get',
    params: parameter
  })
}

// 首页 - 24小时走势图
export function dataTrend(parameter) {
  return axios({
    url: BASE_URL + '/reseller/home_hours_list',
    method: 'get',
    params: parameter
  })
}

// 首页 - 短剧数据
export function dayDrama(parameter) {
  return axios({
    url: BASE_URL + '/reseller/home_day_drama',
    method: 'get',
    params: parameter
  })
}

// ===================================《 推广管理 》

// 推广链接 - 列表
export function popularizeList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/popularize_list',
    method: 'get',
    params: parameter
  })
}

// 推广数据列表
export function adminPopularizeList(parameter) {
  return axios({
    url: BASE_URL + `/reseller/admin_popularize_list`,
    method: 'get',
    params: parameter
  })
}

// 推广链接 - 保存
export function popularizeCreate(parameter) {
  return axios({
    url: BASE_URL + '/reseller/popularize_save',
    method: 'post',
    data: parameter
  })
}

// 推广链接 - 每日数据
export function popularizeDetailData(parameter) {
  return axios({
    url: BASE_URL + '/reseller/popularize_detail_data',
    method: 'get',
    params: parameter
  })
}

// 新建推广链接 - 分销商短剧列表
export function dramaList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/platform_drama_list',
    method: 'get',
    params: parameter
  })
}

// 自定义充值模板 - 所有充值模板列表
export function chargeAll(parameter) {
  return axios({
    url: BASE_URL + '/reseller/charge_all',
    method: 'get',
    params: parameter
  })
}

// 自定义充值模板 - 所有充值模板列表
export function chargeList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/charge_list',
    method: 'get',
    params: parameter
  })
}

// 自定义充值模板 - 充值模板保存
export function chargeSave(parameter) {
  return axios({
    url: BASE_URL + '/reseller/charge_save',
    method: 'post',
    data: parameter
  })
}

// 自定义充值模板 - 充值模板详情
export function chargeDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/charge_detail/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}

// 已回传数据/未回传数据 - 回传数据列表（包含未回传）
export function callbackList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/callback_list',
    method: 'get',
    params: parameter
  })
}

// 回传数据 - 批量回传
export function batchCallback(parameter) {
  return axios({
    url: BASE_URL + '/reseller/batch_callback',
    method: 'post',
    data: parameter
  })
}

// 虚拟回传数据 - 列表
export function callbackVirtualList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/callback_virtual_list',
    method: 'get',
    params: parameter
  })
}

// 虚拟回传数据 - 详情
export function callbackVirtualDetail(parameter) {
  return axios({
    url: BASE_URL + '/reseller/callback_virtual_detail',
    method: 'post',
    data: parameter
  })
}

// 虚拟回传数据 - 回传
export function virtualCallback(parameter) {
  return axios({
    url: BASE_URL + '/reseller/virtual_callback',
    method: 'post',
    data: parameter
  })
}

// ===================================《 IAA推广管理 》

// IAA推广链接 - 列表
export function iaaPopularizeList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/admin_iaa_list',
    method: 'get',
    params: parameter
  })
}

// IAA推广链接 - 保存
export function iaaPopularizeCreate(parameter) {
  return axios({
    url: BASE_URL + '/reseller/iaa/popularize_save',
    method: 'post',
    data: parameter
  })
}

// IAA推广链接 - 每日数据
export function iaaPopularizeDetailData(parameter) {
  return axios({
    url: BASE_URL + '/reseller/iaa/popularize_detail_data',
    method: 'get',
    params: parameter
  })
}

// IAA推广链接 - 自定义列表详情
export function iaaPopularizeMenuDetail(parameter) {
  return axios({
    url: BASE_URL + `/reseller/iaa/menu_detail`,
    method: 'get',
    params: parameter
  })
}

// IAA推广链接 - 自定义列表保存
export function iaaPopularizeMenuSave(parameter) {
  return axios({
    url: BASE_URL + `/reseller/iaa/menu_save`,
    method: 'post',
    data: parameter
  })
}

// IAA推广链接 - 关键事件操作记录
export function iaaPopularizeLog(parameter) {
  return axios({
    url: BASE_URL + `/reseller/iaa/log_iaa`,
    method: 'get',
    params: parameter
  })
}

// IAA推广链接 - 获取刷新时间
export function iaaPopularizeRefreshTime(parameter) {
  return axios({
    url: BASE_URL + `/reseller/iaa/refresh_time`,
    method: 'get',
    params: parameter
  })
}

// IAA推广链接 - 刷新接口
export function iaaPopularizeRefreshData(parameter) {
  return axios({
    url: BASE_URL + `/reseller/iaa/refresh_popularize_data`,
    method: 'get',
    params: parameter
  })
}


// ===================================《 片库 》

// 片库 - 列表
export function resellerList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/list',
    method: 'get',
    params: parameter
  })
}

// 片库 - 剧集列表
export function resellerVideoList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/video_view',
    method: 'get',
    params: parameter
  })
}

// 片库 - 剧集详情
export function resellerDramaDetail(parameter) {
  return axios({
    url: BASE_URL + '/reseller/drama_detail',
    method: 'get',
    params: parameter
  })
}

// ===================================《 数据统计 》

// 每日数据 - 列表
export function popularizeAdvData(parameter) {
  return axios({
    url: BASE_URL + '/reseller/popularize_adv_data',
    method: 'get',
    params: parameter
  })
}

// 短剧数据 - 列表
export function popularizeDramaData(parameter) {
  return axios({
    url: BASE_URL + '/reseller/popularize_drama_data',
    method: 'get',
    params: parameter
  })
}

// ===================================《 用户管理 》

// 注册用户 - 列表
export function userList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/user_list',
    method: 'get',
    params: parameter
  })
}

// 购买记录 - 列表
export function payList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/pay_list',
    method: 'get',
    params: parameter
  })
}

// 消费记录 - 列表
export function consumeList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/consume_list',
    method: 'get',
    params: parameter
  })
}

// 退款记录 - 列表
export function refundList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/refund_list',
    method: 'get',
    params: parameter
  })
}

// ===================================《 结算中心 》

// 每日分成数据 - 列表
export function cashDayList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/cash_day_list',
    method: 'get',
    params: parameter
  })
}

// 我的结算单 - 结算数据信息
export function resellerCash(parameter) {
  return axios({
    url: BASE_URL + '/reseller/reseller_cash',
    method: 'get',
    params: parameter
  })
}

// 我的结算单 - 生成结算信息
export function cashBeginCash(parameter) {
  return axios({
    url: BASE_URL + '/reseller/begin_cash',
    method: 'get',
    params: parameter
  })
}

// 我的结算单 - 分销商结算
export function cashCashSave(parameter) {
  return axios({
    url: BASE_URL + '/reseller/cash_save',
    method: 'post',
    data: parameter
  })
}

// 我的结算单 - 列表
export function cashList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/cash_list',
    method: 'get',
    params: parameter
  })
}

// ===================================《 系统设置 》

// 成员管理 - 列表
export function userResellerList(parameter) {
  return axios({
    url: BASE_URL + '/reseller/user_reseller_list',
    method: 'get',
    params: parameter
  })
}

// 成员管理 - 创建编辑账号接口
export function userResellerisSave(parameter) {
  return axios({
    url: BASE_URL + `/reseller/save_reseller_user`,
    method: 'post',
    data: parameter
  })
}

// 成员管理 - 状态单个开关
export function userResellerisOn(parameter) {
  return axios({
    url: BASE_URL + `/reseller/is_on`,
    method: 'post',
    data: parameter
  })
}