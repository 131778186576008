<template>
  <a-drawer
    :title="formState.id ? '编辑抵扣券规则' : '新建抵扣券规则'"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 注册时长 -->
      <a-form-item
        label="注册时长"
        name="register_duration"
        :rules="[{ required: true, validator: validatorNumber }]"
      >
        <a-input
          v-model:value="formState.register_duration"
          placeholder="请输入"
          :maxlength="30"
          suffix="小时前"
        />
      </a-form-item>
      <!-- 充值次数小于 -->
      <a-form-item
        label="充值次数小于"
        name="pay_num"
        :rules="[{ required: true, validator: validatorInt }]"
      >
        <a-input
          v-model:value="formState.pay_num"
          placeholder="请输入"
          suffix="次"
        />
      </a-form-item>
      <!-- 最大充值金额小于 -->
      <a-form-item
        label="最大充值金额小于"
        name="pay_max_money"
        :rules="[{ required: true, validator: validatorNumber }]"
      >
        <a-input
          v-model:value="formState.pay_max_money"
          placeholder="请输入"
          suffix="元"
        />
      </a-form-item>
      <!-- 抵扣券 -->
      <a-form-item
        label="抵扣券"
        name="deduction_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          placeholder="请选择"
          show-search
          :filter-option="filterOption"
          v-model:value="formState.deduction_id"
        >
          <a-select-option
            v-for="item in deductionList"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 判断顺序 -->
      <a-form-item
        label="判断顺序"
        name="sort"
        :rules="[{ required: true, validator: validatorInt }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.sort"
          placeholder="请输入"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { nextTick, reactive, ref } from 'vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { message } from 'ant-design-vue'
import { popularizeDeductionEdit, frontDeductionAll } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 券列表
let deductionList = ref([])
// 推广链接ID
let advID = ref(undefined)
// 表单参数
const formState = reactive({
  // id
  id: undefined,
  // 注册时长
  register_duration: undefined,
  // 充值次数
  pay_num: undefined,
  // 最大充值金额小于
  pay_max_money: undefined,
  // 抵扣券
  deduction_id: undefined,
  // 顺序
  sort: undefined
})

// 显示
function showDrawer (data) {
  // 显示
  visible.value = true
  // 推广链接必传
  advID.value = data.adv_id
  // 数据
  if (data && data.id) {
    nextTick(() => {
      for(let i in formState) {
        formState[i] = data[i]
      }
    })
  }
  // 抵扣券列表
  getDeductionList()
}

// 抵扣券列表
function getDeductionList () {
  frontDeductionAll().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      deductionList.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 效验正数、最多 2 位小数
function validatorNumber (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}

// 效验正整数
function validatorInt (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}

//  提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 上传数据
    const params = {
      adv_id: advID.value,
      ...formState
    }
    // 新增
    isLoading.value = true
    popularizeDeductionEdit(params).then((res) => {
      isLoading.value = false
      if (res.code === 0) {
        message.success('提交成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('提交失败')
    })
  })).catch((err) => {
    console.log(err)
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
</style>